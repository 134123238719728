/* ------------------------------------------------------------------------------
 *
 *  # Template configurator
 *
 *  Demo JS code for sliding panel with demo config
 *
 * ---------------------------------------------------------------------------- */

// Check localStorage on page load and set mathing theme
// ------------------------------

(function () {
	((localStorage.getItem('theme') === 'auto' &&
		window.matchMedia('(prefers-color-scheme: dark)').matches) ||
		localStorage.getItem('theme') === 'dark') &&
		document.documentElement.setAttribute('data-color-theme', 'dark');
})();

// Setup module
// ------------------------------

const themeSwitcher = (function () {
	//
	// Setup module components
	//

	// Theme
	const layoutTheme = function () {
		const tealTheme = 'light';
		const secondaryTheme = 'dark';
		const storageKey = 'theme';
		const colorscheme = document.getElementsByName('main-theme');
		const mql = window.matchMedia('(prefers-color-scheme: ' + tealTheme + ')');

		// Changes the active radiobutton
		function indicateTheme(mode) {
			for (let i = colorscheme.length; i--; ) {
				if (colorscheme[i].value === mode) {
					colorscheme[i].checked = true;
					colorscheme[i]
						.closest('.list-group-item')
						.classList.add('bg-teal', 'bg-opacity-10', 'border-teal');
				} else {
					colorscheme[i]
						.closest('.list-group-item')
						.classList.remove('bg-teal', 'bg-opacity-10', 'border-teal');
				}
			}
		}

		// Turns alt stylesheet on/off
		function applyTheme(mode) {
			const st = document.documentElement;
			if (mode === tealTheme) {
				st.removeAttribute('data-color-theme');
			} else if (mode === secondaryTheme) {
				st.setAttribute('data-color-theme', 'dark');
			} else {
				if (!mql.matches) {
					st.setAttribute('data-color-theme', 'dark');
				} else {
					st.removeAttribute('data-color-theme');
				}
			}
		}

		// Handles radiobutton clicks
		function setTheme(e) {
			const mode = e.target.value;
			document.documentElement.classList.add('no-transitions');
			if (mode === tealTheme) {
				localStorage.removeItem(storageKey);
			} else {
				localStorage.setItem(storageKey, mode);
			}
			// When the auto button was clicked the auto-switcher needs to kick in
			autoTheme(mql);
		}

		// Handles the media query evaluation, so it expects a media query as parameter
		function autoTheme(e) {
			const current = localStorage.getItem(storageKey);
			let mode = tealTheme;
			let indicate = tealTheme;
			// User set preference has priority
			if (current != null) {
				indicate = mode = current;
			} else if (e != null && e.matches) {
				mode = tealTheme;
			}
			applyTheme(mode);
			indicateTheme(indicate);
			setTimeout(function () {
				document.documentElement.classList.remove('no-transitions');
			}, 100);
		}

		// Create an event listener for media query matches and run it immediately
		autoTheme(mql);
		mql.addEventListener('change', () => autoTheme);

		// Set up listeners for radio button clicks */
		for (let i = colorscheme.length; i--; ) {
			colorscheme[i].onchange = setTheme;
		}
	};

	//
	// Return objects assigned to module
	//

	return {
		init: function () {
			layoutTheme();
		},
	};
})();

// Initialize module
// ------------------------------

document.addEventListener('DOMContentLoaded', function () {
	themeSwitcher.init();
});
